import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EasternWashingtonComponent } from './eastern-washington.component';

const routes: Routes = [{ path: '', component: EasternWashingtonComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EasternWashingtonRoutingModule {}
