import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-eastern-washington',
  templateUrl: './eastern-washington.component.html',
  styleUrls: ['./eastern-washington.component.scss'],
})
export class EasternWashingtonComponent implements OnInit {
  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle('Eastern Washington Manufactured Homes Showcase');
    this.meta.updateTag({
      name: 'description',
      content:
        'Manufactured Homes delivered to all Counties in Eastern Washington from Spokane to Seattle',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: 'Factory ship direct to Eastern Washington',
    });
  }
}
